import React from 'react';
import styled, { css } from 'styled-components';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type BaseQaKodomoPropsType = {
  className?: string;
};

// ◆ご予約
const QA1 = [
  {
    question: '予約方法は？',
    answer:
      'LINEまたはwebから撮影日のご予約ができます。お問い合わせにつきましてもLINEまたはweb予約ページの備考に入力の上お問い合わせください。',
  },
];
// ◆ご来店
const QA2 = [
  {
    question: '小さい子供がいます。撮影当日に機嫌が良いか心配なのですが',
    answer:
      '店内にはベビーベッド、おむつ替えスペース、授乳用のお部屋も用意してありますので、お子様のご機嫌に合わせて撮影をすることができます。また、広い待合スペースがあり、すべり台、ショベルカー、絵本、ブロックなど自由に遊びながらお支度もできますので安心してご来店ください。',
  },
  {
    question: '祖父母も一緒に撮る予定でして、別々でお店に向かうのですが車は停められますか？',
    answer:
      '広い駐車場があり、店内はバリアフリーになっていますのでベッドや車椅子の方も安心してお越しいただけます。また、お着替えスペースもありますのでご自由にご利用いただけます。',
  },
  {
    question: '撮影当日はどのくらい時間がかかりますか？',
    answer:
      '衣装選び〜お支度〜撮影〜写真選びまでの目安は次のとおりです。ただし、衣装選びの時間、撮影ポーズ数、写真選びの時間によって多少前後いたします。家族写真 1時間程度 お宮参り・バースデー 1時間30分程度 七五三3歳または5歳  1時間30分〜2時間程度 七五三7歳 2時間〜2時間30分程度 七五三2名以上 2時間30分〜3時間程度 ',
  },
  {
    question: '当日持って行くものはありますか？',
    answer: 'お子様の衣装についての持ち物は必要ありません。',
  },
];
// ◆衣装選び
const QA3 = [
  {
    question: '衣装は当日決めるのでしょうか？',
    answer: 'はい。撮影当日ご予約の時間にお越しいただき、はじめに衣装をお選びいただいております。',
  },
  {
    question: '家にある着物を持って行くことはできますか？',
    answer:
      'お持ち込みの着物でのお支度は可能です。付属の小物などのご準備をしていただき、当日お持ちください。足りない小物などは撮影時にお貸しすることもできます。',
  },
  {
    question: '基本料金には衣装が2点とありますが、3点着ることはできますか？',
    answer:
      'はい。ご機嫌に応じて着ていただくことは可能です。3点目以降は1点につき3,300円となります。詳しくは料金表をご覧ください。',
  },
  {
    question: '七五三と入学写真の撮影は同じ日にできますか？',
    answer: 'はい。同じ日に撮影は可能です。ランドセル、入学用の衣装などをお持ちください。',
  },
  {
    question: '母親の着物を借りることはできますか？',
    answer:
      'お母様の着物は10点ほどあります。また、お持ち込みの着物でも着付けのみでもご利用いただけます。なお、衣装レンタル、お支度につきましては事前のご予約が必要となります。詳しくは料金表をご覧ください。',
  },
  {
    question: '七五三のメインでないきょうだいの衣装を借りることはできますか？',
    answer:
      '0歳〜10歳くらいまでのお子様衣装がありますのでご利用いただけます。詳しくは料金表をご覧ください。',
  },
  {
    question: '中学生の姉も一緒にドレスを着たいのですが衣装はありますか？',
    answer:
      '店内には10歳くらいまでのサイズのドレスがあります。衣装は大きめのサイズとなっていますので、サイズが合いましたらお子様のごきょうだい価格でご利用できます。なお、サイズが合わない場合は大人用のドレスとなります。詳しくは料金表をご覧ください。',
  },
  {
    question: '撮影終了後にそのままお宮参りに行きたいのですが、衣装を借りることはできますか？',
    answer:
      '撮影後、お写真選びをした後にそのままお出かけすることが可能です。お日柄の良い土日の午前中は予約が取りににくくなりますので早めのご予約をおすすめします。 詳しくは料金表をご覧ください。',
  },
];
// ◆お支度・撮影
const QA4 = [
  {
    question: '支度中にスマホやビデオで撮影はできますか？',
    answer: 'お支度中の撮影は可能です。撮影中もカメラマンの妨げにならない程度の撮影は可能です。',
  },
  {
    question: 'きょうだいや家族での写真も撮れますか？',
    answer:
      'ごきょうだいで撮影、家族撮影、祖父母様と一緒に大家族撮影もお撮りすることができます。(撮影料 各3,300円)　詳しくは料金表をご覧ください。',
  },
];
// ◆写真選び
const QA5 = [
  {
    question: '撮影した写真はいつ選ぶのでしょうか？',
    answer:
      '撮影が終了後、お着替えが済みましたらそのまま写真選びとなります。選びながら、台紙アルバム、写真集、額、データなどの商品をお選びください。写真選びはおおよそ20〜30分程度となります。',
  },
];
// ◆写真・データ・その他商品
const QA6 = [
  {
    question: '撮影したデータは販売していますか？',
    answer:
      '台紙アルバム・写真集を購入された場合に、オプションとしてデータ販売をしています。詳しくは料金表をご覧ください。',
  },
];
// ◆お支払い・商品の仕上がり
const QA7 = [
  {
    question: '支払い方法は現金ですか？',
    answer: '撮影当日に現金またはカードでのお支払いとなります。',
  },
  {
    question: '商品はどのくらいで仕上がりますか？',
    answer:
      '2週間ほどで仕上がり、宅急便でご自宅にお届けします。なお、お客様に送料のご負担はございません（当社送料負担）。仕上がりをお急ぎの場合は撮影当日にお知らせください。',
  },
];

const BaseQaKodomo: React.FC<BaseQaKodomoPropsType> = ({ className }) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={className}>
      <div className="row">
        <div className="col-12">
          <div>
            <h2>よくあるご質問</h2>

            <h3>
              <span>ご予約</span>
            </h3>

            <div className="accordion">
              {React.Children.toArray(
                QA1.map((item, index) => (
                  <Accordion
                    expanded={expanded === `panel1-${index}`}
                    onChange={handleChange(`panel1-${index}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className="heading">Q.&nbsp;{item.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>A.&nbsp;{item.answer}</Typography>
                    </AccordionDetails>
                  </Accordion>
                )),
              )}
            </div>

            <h3>
              <span>ご来店</span>
            </h3>

            <div className="accordion">
              {React.Children.toArray(
                QA2.map((item, index) => (
                  <Accordion
                    expanded={expanded === `panel2-${index}`}
                    onChange={handleChange(`panel2-${index}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className="heading">Q.&nbsp;{item.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>A.&nbsp;{item.answer}</Typography>
                    </AccordionDetails>
                  </Accordion>
                )),
              )}
            </div>

            <h3>
              <span>衣装選び</span>
            </h3>

            <div className="accordion">
              {React.Children.toArray(
                QA3.map((item, index) => (
                  <Accordion
                    expanded={expanded === `panel3-${index}`}
                    onChange={handleChange(`panel3-${index}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className="heading">Q.&nbsp;{item.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>A.&nbsp;{item.answer}</Typography>
                    </AccordionDetails>
                  </Accordion>
                )),
              )}
            </div>

            <h3>
              <span>お支度・撮影</span>
            </h3>

            <div className="accordion">
              {React.Children.toArray(
                QA4.map((item, index) => (
                  <Accordion
                    expanded={expanded === `panel4-${index}`}
                    onChange={handleChange(`panel4-${index}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className="heading">Q.&nbsp;{item.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>A.&nbsp;{item.answer}</Typography>
                    </AccordionDetails>
                  </Accordion>
                )),
              )}
            </div>

            <h3>
              <span>写真選び</span>
            </h3>

            <div className="accordion">
              {React.Children.toArray(
                QA5.map((item, index) => (
                  <Accordion
                    expanded={expanded === `panel5-${index}`}
                    onChange={handleChange(`panel5-${index}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className="heading">Q.&nbsp;{item.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>A.&nbsp;{item.answer}</Typography>
                    </AccordionDetails>
                  </Accordion>
                )),
              )}
            </div>

            <h3>
              <span>写真・データ・その他商品</span>
            </h3>

            <div className="accordion">
              {React.Children.toArray(
                QA6.map((item, index) => (
                  <Accordion
                    expanded={expanded === `panel6-${index}`}
                    onChange={handleChange(`panel6-${index}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className="heading">Q.&nbsp;{item.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>A.&nbsp;{item.answer}</Typography>
                    </AccordionDetails>
                  </Accordion>
                )),
              )}
            </div>

            <h3>
              <span>お支払い・商品の仕上がり</span>
            </h3>

            <div className="accordion">
              {React.Children.toArray(
                QA7.map((item, index) => (
                  <Accordion
                    expanded={expanded === `panel7-${index}`}
                    onChange={handleChange(`panel7-${index}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className="heading">Q.&nbsp;{item.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>A.&nbsp;{item.answer}</Typography>
                    </AccordionDetails>
                  </Accordion>
                )),
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const QaKodomo = styled(BaseQaKodomo)`
  ${() => {
    return css`
      & {
        .heading: {
          font-size: 14px;
          flex-basis: '33.33%';
          flex-shrink: 0;
        }
        .secondaryheading: {
          font-size: 14px;
          color: theme.palette.text.secondary;
        }
        h2 {
          margin-top: 10px;
          padding: 1.65rem 2rem;
          background-color: rgba(165, 210, 255, 0.4);
          background-image: linear-gradient(90deg, rgba(165, 210, 255, 0.3) 50%, transparent 50%),
            linear-gradient(rgba(165, 210, 255, 0.3) 50%, transparent 50%);
          background-size: 40px 40px;
          border-radius: 6px;
        }
        h3 {
          margin: 10px 0;
          padding: 20px 20px;
          background: #f4f4f4;
          border-radius: 6px;
        }
        h3 span {
          position: relative;
          display: inline-block;
          width: 100%;
          padding-left: 12px;
          border-left: 4px solid pink;
        }
        .accordion {
          margin: 20px 0;
        }
      }
    `;
  }}
`;

export default React.memo(QaKodomo);
