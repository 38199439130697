import React from 'react';
import styled, { css } from 'styled-components';
import { Category } from '../const/type';

type BaseOptionPropsType = {
  className?: string;
  category?: Category;
};

const BaseOption: React.FC<BaseOptionPropsType> = ({ className, category = '' }) => (
  <div className={className}>
    <div className="row">
      {category !== 'seijinshiki' && (
        <>
          <div className="col-12">
            <h2>
              <span>プリント料金</span>
            </h2>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>２面台紙</td>
                  <td>19,800円</td>
                </tr>
                <tr>
                  <td>３面台紙</td>
                  <td>26,400円</td>
                </tr>
                <tr>
                  <td>４面台紙</td>
                  <td>35,200円</td>
                </tr>
                <tr>
                  <td colSpan={2}>その他 全カットデータ・額・写真などございます</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-12">
            <h2>
              <span>ごきょうだいで撮影</span>
            </h2>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td colSpan={2}>衣装レンタル料金(税込)</td>
                </tr>
                <tr>
                  <td>スーツまたはドレス</td>
                  <td>3,300円</td>
                </tr>
                <tr>
                  <td>140cmサイズ以上のドレス</td>
                  <td>11,000円</td>
                </tr>
              </tbody>
            </table>
            <div className="spacer30"></div>
            <div className="card">
              <div className="card-body">
                <h5 className="caption-title">ごきょうだいで撮影に含まれるもの</h5>
                <ul>
                  <li>撮影時のスーツまたはドレス１着分レンタル</li>
                  <li>スーツまたはドレスからお選びいただけます</li>
                  <li>衣装に合わせた着付け料、ヘアセット料が含まれています</li>
                </ul>
                <div className="spacer30"></div>
                <ul className="">
                  <li>
                    <strong>和装は含まれていません。</strong>
                  </li>
                  <li>
                    <strong>
                      和装ご希望の場合はお子様の年齢に応じて七五三の基本料金が別途かかります。
                    </strong>
                  </li>
                </ul>
                <div className="spacer30"></div>
                <ul className="">
                  <li>消費税込の価格です</li>
                  <li>お子様１人の料金です。</li>
                </ul>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="col-12">
        <h2>
          <span>家族写真</span>
        </h2>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td colSpan={2}>撮影料金(税込)</td>
            </tr>
            <tr>
              <td>家族写真</td>
              <td>3,300円</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-12">
        <h2>
          <span>オプション料金</span>
        </h2>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>衣装を着てお出かけ</td>
              <td>11,000円</td>
            </tr>
            <tr>
              <td>お母様の着付料</td>
              <td>8,800円</td>
            </tr>
            <tr>
              <td>お母様のヘアセット</td>
              <td>4,400円</td>
            </tr>
            <tr>
              <td>お母様のメイク</td>
              <td>3,300円</td>
            </tr>
            <tr>
              <td>お母様着物レンタル</td>
              <td>11,000円</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

const Option = styled(BaseOption)`
  ${() => {
    return css`
      & {
        .spacer10 {
          margin-bottom: 10px;
        }

        .spacer20 {
          margin-bottom: 20px;
        }

        .spacer30 {
          margin-bottom: 30px;
        }

        .spacer50 {
          margin-bottom: 50px;
        }

        .spacer100 {
          margin-bottom: 100px;
        }

        .flex-wrapper {
          display: flex;
          flex-direction: column;
        }

        .flex-wrapper-row {
          display: flex;
          flex-direction: row;
        }

        .flex-item {
          padding: 0;
          line-height: 2.5;
        }

        .w33 {
          width: 33%;
        }

        .w100 {
          width: 100%;
        }

        .pw10 {
          padding: 0 10px 0;
        }

        .flex-spacer {
          flex: 1;
          padding: 0 10px;
        }

        .plan-wrap {
          margin: 0 0 10px;
          padding: 10px;
          border: solid 1px #eee;
          border-radius: 10px;
        }

        .plan-title {
          padding: 10px 0;
          margin: 10px 0;
          font-size: 1.4rem;
          font-weight: 200;
          line-height: 1.2rem;
        }

        .plan-subtitle {
          font-weight: 700;
          margin-bottom: 0.5rem;
        }

        .price-memo {
          font-weight: 700;
        }

        .plan-price {
          font-size: 1.4rem;
          font-weight: 700;
          color: #dc3545;
          letter-spacing: -0.05rem;
        }

        .option-title {
          font-size: 1.5rem;
          font-weight: 500;
        }

        .form-title {
          font-size: 1.2rem;
          font-weight: 500;
          margin-bottom: 1rem;
        }

        table thead th {
          text-align: center;
          width: 50%;
        }

        table tbody tr th,
        td {
          text-align: center;
          width: 50%;
        }

        ul,
        li {
          margin: 0;
        }

        .caption-title {
          font-weight: 700;
          text-align: center;
          margin-bottom: 30px;
        }

        .carousel-wrapper {
          margin: 30px;
        }
        .total-price-wrapper {
          margin-top: 30px;
          background: #f3b54a;
          padding: 10px 30px;
          border-radius: 6px;
        }
        .total-price {
          font-weight: 700;
          font-size: 1.5rem;
          color: white;
        }

        h2 {
          position: relative;
          margin: 30px;
          text-align: center;
        }

        h2:before,
        h2:after {
          position: absolute;
          z-index: 0;
          bottom: -10px;
          display: block;
          content: '';
          border: 1em solid rgb(190, 36, 88);
        }

        h2:before {
          left: -30px;
          border-left-width: 15px;
          border-left-color: transparent;
        }

        h2:after {
          right: -30px;
          border-right-width: 15px;
          border-right-color: transparent;
        }

        h2 span {
          position: relative;
          z-index: 1;
          display: block;
          padding: 1rem 2rem;
          color: #fff;
          background: rgb(245, 90, 141);
        }

        h2 span:before,
        h2 span:after {
          position: absolute;
          bottom: -10px;
          display: block;
          width: 10px;
          height: 10px;
          content: '';
          border-style: solid;
          border-color: #b70505 transparent transparent transparent;
        }

        h2 span:before {
          left: 0;
          border-width: 10px 0 0 10px;
        }

        h2 span:after {
          right: 0;
          border-width: 10px 10px 0 0;
        }
        @media screen and (max-width: 480px) {
          .flex-item {
            margin-bottom: 10px;
          }
          .flex-wrapper-row {
            flex-direction: column;
          }
          .total-price {
            font-size: 1.2rem;
          }
        }
      }
    `;
  }}
`;

export default React.memo(Option);
